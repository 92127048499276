import axios from 'axios';
import authHeader from './auth-header';
import authHeaderFile from './auth-header-file';

import { URL_API } from './Commons';

class ApoioService {

  async getAll() {
    return await axios.get(URL_API + 'apoios', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'apoio/' + id, { headers: authHeader() });
  }

  insert(apoio) {
    return axios.post(URL_API + 'apoio', apoio, { headers: authHeader() });
  }

  update(id, apoio) {
    return axios.put(URL_API + 'apoio/' + id, apoio, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'apoio/' + id, { headers: authHeader() });
  }
  sendFileImg(id, formData) {
    return axios.post(URL_API + 'apoioupimagem/' + id, formData, { headers: authHeaderFile() });
  }

}

export default new ApoioService();