class ApoioHandle {

    formToJso(formData) {
        return {
            id: formData.id,
            nome: formData.nome,
            url: formData.url,
            status: formData.status,
        };
    }

}

export default new ApoioHandle();