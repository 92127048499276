<template>
  <div class="Logado">
    <vr-toast :modelValue="toastList" :key="toastList"></vr-toast>
    <div class="container">
      <div class="row mt-2">
        <nav aria-label="breadcrumb" class="bg-light rounded">
          <ol class="breadcrumb small mt-2">
            <li class="breadcrumb-item">
              <a href="/" class="text-muted">{{ $t("home") }}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $t("sponsorships") }}
            </li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-10 mx-auto">
          <vr-table-paginate
            :labels="labels"
            :list="list"
            :pageSize="10"
            :actionNew="openModalInsert"
            :actionEdit="openModalEdit"
            :actionDelete="openModalDelete"
            :fieldsFilter="['nome']"
            :btnNew="true"
            :actions="[
              {
                name: 'edit',
                class: 'btn btn-sm btn-warning',
                action: openModalEdit,
                ico: 'bi bi-pencil',
              },
              {
                name: 'delete',
                class: 'btn btn-sm btn-danger',
                action: openModalDelete,
                ico: 'bi bi-eraser-fill',
              },
            ]"
          ></vr-table-paginate>
        </div>
      </div>
    </div>
    <!-- Start Modal Insert -->
    <div class="modal fade" ref="insertModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-success text-white">
            <h5 class="modal-title" id="insertModalLabel">
              {{ $t("new") }} {{ $t("sponsorship") }}
            </h5>
            <a href="#" class="text-white" @click="modalInsert.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div class="modal-body">
            <form-insert-apoio :actionSend="sendNew" > </form-insert-apoio>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal insert -->
    <!-- Start Modal Edit -->
    <div class="modal fade" ref="editModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div v-if="deleteMode" class="modal-header bg-danger text-white">
            <h5 class="modal-title" id="editModalLabel">
              <i class="bi bi-eraser-fill"></i> {{ $t("delete") }}
              {{ $t("sponsorship") }}
            </h5>
            <a href="#" class="text-white" @click="modalEdit.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div v-if="!deleteMode" class="modal-header bg-warning">
            <h5 class="modal-title" id="editModalLabel">
              <i class="bi bi-pencil"></i> {{ $t("edit") }} {{ $t("sponsorship") }}
            </h5>
            <a href="#" class="text-dark" @click="modalEdit.hide()"
              ><i class="bi bi-x-lg"></i
            ></a>
          </div>
          <div class="modal-body">            
            <form-edit-apoio
              :dataItem="formEdit"
              :actionSend="sendEdit"
              :errorServer="errorServer"
              :key="keyFormEditApoio"
              :ActionSendFile="sendFile"
              :deleteMode="deleteMode"
              :toastShow="toastShow"
            >
            </form-edit-apoio>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal Edit -->
    
    
    
  </div>
</template>

<script>
import ApoioService from "../../../../services/apoio.service";
import ApoioHandle from "../../../../services/handle/apoio.handle";
import FormInsertApoio from "@/views/protected/pages/apoio/comp/formInsertApoio.vue";
import FormEditApoio from "@/views/protected/pages/apoio/comp/formEditApoio.vue";


import { Modal } from "bootstrap";
import { getCurrentInstance } from "vue";
import VrToast from "../../../../components/VrToast.vue"; 
import VrTablePaginate from "../../../../components/VrTablePaginate.vue";

export default {
  name: "Apoio",
  data: () => ({
    modalInsert: null,
    modalEdit: null,
    deleteMode: false,
    list: [],
    formInclude: {},
    formEdit: {},
    keyFormEditApoio: "",
    app: null,
    toastList: [],
    lengthPerPage: 0,
    atualPage: 1,
    labels: [],
  }),
  components: {
    VrToast,
    VrTablePaginate,
    FormInsertApoio,
    FormEditApoio,
  },
  mounted() {
    this.app = getCurrentInstance();
    this.modalInsert = new Modal(this.$refs.insertModal);
    this.modalEdit = new Modal(this.$refs.editModal);

    this.labels = [
      { key: "id", type: "number" },
      { key: "nome", type: "text" },
      { key: "created_at", type: "date" },
      {
        key: "status",
        type: "boolean",
        options: { true: "Ativo", false: "Inativo" },
      },
    ];
    this.reloadList();
  },
  methods: {
    reloadList: function () {
      ApoioService.getAll().then((result) => {
        if (result.status >= 200 && result.status < 400) {
          this.list = result.data;
        }
      }, this.errorServer);

    },
    openModalInsert: function () {
      this.modalInsert.show();
    },
    openModalEdit: function (item) {
      //console.log(item);
      ApoioService.getOne(item.id).then((result) => {
        if (result.status >= 200 && result.status < 400) {
          this.formEdit = result.data;
          this.keyFormEditApoio = Math.random().toString(36).slice(2);
          this.deleteMode = false;
          this.modalEdit.show();
        }
      }, this.errorServer);
    },
    openModalDelete: function (item) {
      ApoioService.getOne(item.id).then((result) => {
        if (result.status >= 200 && result.status < 400) {
          this.formEdit = result.data;
          this.keyFormEditApoio = Math.random().toString(36).slice(2);
          this.deleteMode = true;
          this.modalEdit.show();
        }
      }, this.errorServer);
    },
    sendNew: function (objectForm) {
      //console.log(objectForm);
      ApoioService.insert(ApoioHandle.formToJso(objectForm)).then(
        (result) => {
          //console.log(result);
          result;
          this.cleanForm();
          this.toastShow({ msg: "O item foi inserido com Sucesso.", style: "success" });
        },
        this.errorServer
      );
    },
    sendEdit: function (objectForm) {
      this.cleanForm();
      this.toastShow({ msg: objectForm.msg, style: objectForm.style });
    },
    sendFile: function () {
      this.toastShow({ msg: "O arquivo foi alterado com Sucesso.", style: "success" });
    },
    cleanForm: function () {
      this.formInclude = {};
      this.deleteMode = false;
      this.modalInsert.hide();
      this.modalEdit.hide();
      // this.toastList = []
      this.reloadList();
    },
    toastShow: function (alert) {
      this.toastList = [];
      this.toastList.push(alert);
    },
    errorServer: function (error) {
      this.toastShow({ msg: "Falha ao enviar dados (403)", style: "danger" });
      if (error.message.includes("403")) {
        this.app.appContext.config.globalProperties.$router.push("/login");
      }
    },
  },
};
</script>