<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <form @submit="sendEdit">
      <div class="col-12 mb-3">
        <label for="nome" class="form-label">Nome</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.nome"
          aria-describedby="nome"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="url" class="form-label">URL</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.url"
          aria-describedby="url"
        />
      </div>
      <div class="card p-2 mb-3">
        <div class="mb-3">
          <div class="row">
            <label for="image_path" class="form-label">Imagem / Banner</label>
            <img
              :src="urlImg"
              :key="formEdit.image_path"
              class="img-fluid img-thumb-200"
            />
          </div>
        </div>
        <div class="mb-3" v-if="!deleteMode">
          <input
            ref="pathimagem"
            type="file"
            class="form-control"
            id="path_input"
            accept="image/*"
            @change="sendImagem()"
          />
          <div id="emailHelp" class="form-text">
            <small>
              Tamanho da Imagem/Banner: <b>300x160px</b> / Formato da Imagem:
              <b>.png, .jpg, .jpeg</b></small
            >
          </div>
        </div>
      </div>
      <div class="col-12 mb-3" v-if="!deleteMode">
        <label for="lang_input" class="form-label">{{ $t("status") }}</label>
        <select
          class="form-select"
          @change="formEdit.status = $event.target.value"
          v-model="formEdit.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { URL_IMAGE } from "../../../../../services/Commons";
import ApoioService from "../../../../../services/apoio.service";
import ApoioHandle from "../../../../../services/handle/apoio.handle";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    urlPdf: "",
    loading: false,
    moment: null,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.urlImg = URL_IMAGE + `apoiodowimagem/${this.dataItem.id}/`;
    this.formEdit = this.dataItem;
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      //
      if (this.deleteMode) {
        ApoioService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({
              msg: "O item foi excluído com Sucesso.",
              style: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      ApoioService.update(
        this.formEdit.id,
        ApoioHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          result;
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
    sendImagem: function (field) {
      this.loading = true;
      if (this.$refs["pathimagem"].files[0]) {
        let formData = new FormData();
        formData.append("file", this.$refs["pathimagem"].files[0]);
        ApoioService.sendFileImg(this.formEdit.id, formData).then(
          (result) => {
            this.formEdit[field] = result.data;
            this.loading = false;
            this.ActionSendFile();
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }
    },
  },
};
</script>

<style>
</style>